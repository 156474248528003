/*-------------------------------------------------
GLOBALS CLASSES 
-------------------------------------------------*/
*{padding:0; margin:0;}
body{font-family: 'Droid Serif', serif; font-size:12px; color:#9b9e9a; background:#e1ece7;}
img{border:none;}
h1{ font-size:18px; color:#d13f31; font-family: 'Oswald', sans-serif; font-weight:normal;/* text-transform:uppercase;*/}
h2{ font-size:14px; color:#1f7872; font-family: 'Droid Serif', serif; font-weight:normal;}
P{ line-height:20px;}
a{text-decoration:none; color:#1f7872;}
a:hover{color:#d13f31;}
/*-------------------------------------------------
GENERAL CLASSES
-------------------------------------------------*/
.mar-top30{margin-top:30px;}
.mar-top20{margin-top:20px;}
.mar-right30{margin-right:30px;}
.mar-bottom20{margin-bottom:20px;}
.float-left{ float:left;}
.float-right{ float:right;}
.clearing { clear:both;}
.panel{ background:#ffffff;}
.title{}
.content{}
.button a{text-decoration:none; display:block; width:70px; height:30px; background:#1f7872; color:#ffffff; font-family: 'Oswald', sans-serif; line-height:30px; text-align:center; text-transform:uppercase;}
.button a:hover{ background:#d13f31;}
.page{ width:960px; margin:0 auto;overflow:hidden;}
/*-------------------------------------------------
MENU CLASSES
-------------------------------------------------*/
.menu-wrap{ overflow:hidden; background:#1a202c;}
.menu{margin:0 auto; width:960px; background:#d13f31; height:85px; margin-top:80px;}
.menu ul li{list-style:none; float:left; width:auto;}
.menu ul li a{ display:block; height:85px; line-height:85px; padding-left:30px; padding-right:30px; font-size:14px; font-family: 'Oswald', sans-serif; color:#ffffff; border-right:#dd503f solid 1px; text-transform:uppercase;}
.menu ul li a:hover{background:#e1ece7; color:#1a202c;}
.menu ul li a.active{background:#e1ece7; color:#1a202c;}

/*-------------------------------------------------
Client MENU CLASSES
-------------------------------------------------*/
.cmenu-wrap{ overflow:hidden; background:#1a202c;}
.cmenu{margin:0 auto; width:960px; background:#d13f31; height:85px; margin-top:5px;}
.cmenu ul li{list-style:none; float:left; width:auto;}
.cmenu ul li a{ display:block; height:85px; line-height:85px; padding-left:25px; padding-right:25px; font-size:14px; font-family: 'Oswald', sans-serif; color:#ffffff; border-right:#dd503f solid 1px; text-transform:uppercase;}
.cmenu ul li a:hover{background:#e1ece7; color:#1a202c;}
.cmenu ul li a.active{background:#e1ece7; color:#1a202c;}

/*-------------------------------------------------
HEADER CLASSES
-------------------------------------------------*/
.header{margin:0 auto; width:960px; height:120px;}
.logo{width:600px; float:left; line-height:120px;}
.logo h1{ font-size:32px; color:#d13f31; font-weight:normal;}
.logo h1 span{ color:#1a202c;}

.social{ float:right; margin-top:45px;}
.social ul li{list-style:none; float:left; margin-left:15px;}

/*-------------------------------------------------
BANNER CLASSES
-------------------------------------------------*/
.banner{width:960px; height:340px; margin:0 auto; margin-bottom:60px; background:url(./images/banner-image.jpg) no-repeat; position:relative;}
.banner h1{ padding:10px; float:right; background:#1a202c; text-transform:uppercase; color:#ffffff;  font-size:30px; font-weight:normal; position:absolute; top:130px; right:0;}
.banner h2{ padding:10px; float:right; background:#ffffff; text-transform:uppercase; color:#1a202c;  font-size:22px; font-weight:normal; font-family: 'Oswald', sans-serif;position:absolute; top:195px; right:0;}
/*-------------------------------------------------
MAIN COLUMAS CLASSES
-------------------------------------------------*/
.primary-reg{ width:960px; overflow:hidden; float:left;}
.primary-col{ width:650px; overflow:hidden; float:left;}
.primary-col p{ padding-bottom:20px;}
.generic{ float:left; overflow:hidden; width:100%;}
.generic .panel{ padding:30px 30px 30px 30px;}
.generic .title h1{ padding-bottom:20px;}
.generic .content{overflow:hidden;}
.generic .content h2{ padding-bottom:15px;}
.block{width:310px;}
.block .panel{padding:50px 30px 30px 30px;}
.block .title h1{ padding-bottom:20px;}
.block .content img{ padding-bottom:20px;}
.block .content h2{ padding-bottom:10px;}
.block .content p{ padding-bottom:20px;}

/*-------------------------------------------------
SIDE BAR CLASSES
-------------------------------------------------*/
.side-bar{ width:280px; overflow:hidden; float:right;}
.search{background:#d13f31; padding:25px 20px 25px 20px; overflow:hidden;}
.search p{font-size:16px; color:#fff; font-weight:normal;}
.search .title h1{ color:#fff; padding-bottom:20px;}
.search ul li{list-style:none; float:left; list-style:none;}
.textfiled{ background:#fff; width:200px; height:36px;  text-indent:5px; border: none;}
.side-bar .panel{ padding:40px 20px 25px 20px; overflow:hidden;}
.side-bar .panel .title{padding-bottom:25px;}
.side-bar .panel .title span{float:left; padding-right:12px;}
.side-bar .content ul li{list-style:none; border-bottom:#e2e6e9 solid 1px;color:#9b9e9a;line-height:30px;}
.side-bar .content ul li.-no-border-bottom{border-bottom:none;}
.side-bar .content ul li a{ text-decoration:none;color:#9b9e9a;}
.side-bar .content ul li a:hover{ text-decoration:none; color:#d13f31;}
.side-bar .content img{float:left;}
.side-bar .content .recentPost{ float:right; width:130px; line-height:18px;}
.side-bar .content .recentPost a{ text-decoration:none; color:#1f7872; float:left; padding-top:20px;}
.side-bar .content .recentPost a:hover{ color:#d13f31;}

/*-------------------------------------------------
CONTACT FORM CLASSS
-------------------------------------------------*/

.contact-form { background:#f4f8f6; padding:30px; width:440px; float:left;}
.contact-form label {display: block; padding:10px 0 10px 0;}
.contact-form label span {display: block; color:#939191;font-size:14px; float:left; width:80px; text-align:left; padding:5px 20px 0 0;}
.contact-form .input_text {padding:10px 10px;width:318px;background:#ffffff;border:#e1ece7 1px solid; color:#939191;}
.contact-form .message{padding:10px 10px;width:318px; background:#ffffff; border:#e1ece7 1px solid;overflow:hidden;height:150px; color:#939191; font-size:14px;}
.contact-form .button{padding:8px;background:#1f7872; color:#ffffff; text-transform:uppercase; font-family:'Oswald', sans-serif;border:0px solid;margin-left:100px;margin-top:20px;}

.address { float:left; width:370px; margin-left:30px;}
.address .panel { border:none; color:#9b9e9a}
.address .panel .title h1 {  color:#d13f31; padding-bottom:10px;}
.address .panel .content p span { color:#9b9e9a;}


/*-------------------------------------------------
FOOTER CLASSES
-------------------------------------------------*/
.primary-footer{ overflow:hidden; margin:0 auto;}
.footer-wrap{ background:#1a202c; overflow:hidden; padding-top:50px; margin-top:50px; padding-bottom:50px;}
.footer{width:960px; margin:0 auto;}
.footer .panel{float:left; background:none; width:300px;}
.footer .panel .title h1{ color:#ffffff;}
.footer .panel .content{padding-top:25px;}
.footer .panel .content p{ color:#435059;}
.footer .panel .content .button a{ margin-top:30px; background:#d13f31; width:70px; height:30px;}
.footer .panel .content .button a:hover{ margin-top:30px; background:#1f7872;}
.footer .panel .content ul li{ list-style:url(./images/arrow.png) inside; border-bottom:#273042 solid 1px; line-height:35px;}
.footer .panel .content ul li a{text-decoration:none; color:#435059;}
.footer .panel .content ul li a:hover{color:#d13f31;}
.footer .panel .content ul li.-no-border-bottom{border-bottom:none;}
.copyright-wrap{ background:#1f7872; overflow:hidden; padding:40px 0px 40px 0px;}
.copyright-wrap .panel{width:960px; margin:0 auto; background:none;}
.copyright-wrap .panel .content p{ color:#ffffff; text-align:center; font-size:12px;}
.copyright-wrap .panel .content p a{ text-decoration:none; color:#ffffff;}
.copyright-wrap .panel .content p a:hover{ color:#1a202c;}

.ccopyright-wrap{ background:#1f7872; overflow:hidden; padding:5px 0px 5px 0px;}
.ccopyright-wrap .panel{width:960px; margin:0 auto; background:none;}
.ccopyright-wrap .panel .content p{ color:#ffffff; text-align:center; font-size:12px;}
.ccopyright-wrap .panel .content p a{ text-decoration:none; color:#ffffff;}
.ccopyright-wrap .panel .content p a:hover{ color:#1a202c;}

/*-------------------------------------------------
Form Field CLASSES
-------------------------------------------------*/

/*
.form-field {width:960px;overflow:hidden; auto;padding-top:5px; margin-top:5px; padding-bottom:5px;}
.mar-break{margin-top:30px;}
*/

.logins{margin:0 auto; width:960px;float:center}


#clientprofile {

margin-top:10px;
padding:10px;
width:500px;
height:570px;
}

#clientprofile  label{
text-align:right;
padding-right:10px;
width:150px;
}

#clientprofile  input{
width:250px;
}

#clientprofile  select{
width:250px;
}

#clientprofile  label, #clientprofile  input, #clientprofile  select {
display:block;
float:left;
margin-bottom:10px;
}


#clientbank {

margin-top:10px;
padding:10px;
width:500px;
height:110px;
}

#clientbank  label{
text-align:right;
padding-right:10px;
width:150px;
}

#clientbank  input{
width:250px;
}

#clientbank  select{
width:250px;
}

#clientbank  label, #clientbank  input, #clientbank  select {
display:block;
float:left;
margin-bottom:10px;
}


#terms {
margin-top:10px;
padding:10px;
width:500px;
height:100px;
}

#terms  label{
text-align:left;
padding-right:10px;
width:450px;
}

#terms  input{
text-align:left;
padding-right:10px;
width:50px;
}

#terms  p{
text-align:left;
padding-right:10px;
width:440px;
}

#terms  ol{
text-align:left;
padding-right:10px;
margin-left:10px;
width:450px;
}
#terms  label ,#terms  input,#terms  p,#terms  ol{
display:block;
float:left;
margin-bottom:10px;
}


#bankin {
margin-top:10px;
padding:10px;
width:500px;
height:10px;
}

#bankin  label{
text-align:right;
padding-right:10px;
width:150px;
}

#bankin  select{
width:50;
}

#bankin  label, #bankin  select {
display:block;
float:left;
margin-bottom:10px;
}


.label-wrap{overflow:hidden;}
.label{margin:0 auto; width:960px;float:left;display:block;}

.label-wrap{ overflow:hidden;}
.label{margin:0 auto; width:960px;}
.label h2{display:block;list-style:none; float:left; width:130px;}

.forinput {width:500px;display:flex;align:left;}
.forinput label{width:250px;align:left}

